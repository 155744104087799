<template>
  <div>
   <h1>ご注文状況確認</h1>

   <div class="statusinfo">
  登録されたユーザでログインした状態でお申込みされた商品のご注文状況は、<br>ログイン後マイページよりご確認いただけます。
</div>

<login to="/mypage/" v-if="!logined" />
<hr>

<div v-if="logined" class="bn_tomypage">
  
          <router-link @click.native="scrollToTop" to="/mypage/">
          マイページへ
          </router-link>
</div>

<div class="statusinfo">
          ユーザ登録をしていない方、またはログインをしない状態でお申し込みをされた場合は、<br>
          ご注文受付時に配信されるメールに、商品のご注文状況確認URLを記載しております。<br>
<br>
          <strong>メールを紛失された方は、以下のフォームより必要事項をご記入いただくことで、<br>
          個別にご注文状況をご確認いただくことができます。</strong><br>
          </div>

<div class="statusform">
<dl>
  <dt>
お手紙番号<!--、またはご注文番号：-->
</dt>
  <dd>
    <input type="text" v-model="orderno">
    <p class="info">
※お手紙番号は、SW、SPなどからはじまる番号（例：SW-123456）
<!--<br>※ご注文番号は、数字番号（例：12345678）-->
<br><br>

※お手紙番号からの検索では、一緒に申し込みされた商品を含めたご注文全体をご確認いただけます。</p>
  </dd>
</dl>

<dl>
  <dt>
ご依頼主(ご注文者)様の電話番号、またはメールアドレス：	
</dt>
  <dd>
<input type="text" v-model="orderkey">
<p class="info">例：080-1234-5678 または sample@sample.com</p>

  </dd>
</dl>


<button @click="submit">確認する</button>

</div>

<fullloader :loading="fullloading" />


    <dialogalert
      v-model="visible_dialog"
      :title="dialog_title"
      :info="dialog_info"
      :button_ok="dialog_bn_ok"
      :button_cancel="dialog_bn_cancel"
      :ok_fn="dialog_ok_fn"
    />


  </div>
</template>

<script>
import login from '@/components/login';
export default  {
    data:function(){return {
      orderno:"",
      orderkey:"",
      ordertoken:"",
    }},
    components:{
        login
    },
    created: function() { this.$parent.middle = 2; },
    computed:{
      /*token:function(){
        return this.$store.state.token;
      }*/
    },
    methods:{
      submit:function(){
        if(!this.orderno||!this.orderkey){
         // return;
        }
        //this.fullloading=true;
        this.fetch("getstatus",{orderno:this.orderno,orderkey:this.orderkey}).then((data)=>{
          this.fullloading=false;
          console.log(data)
          if(data.error){
            this.opendialog({title:"ご確認ください",info:data.error,bn_cancel:"OK"});
          }else{
            this.ordertoken=data.ordertoken.value;
            this.opendialog({title:"ご注文が見つかりました。",info:"ご注文状況確認のページへ移動しますか？",
            bn_ok:"移動する",
            bn_cancel:"キャンセル",
            ok_fn:()=>{
              if(!this.ordertoken)return;
              this.$router.push("/item/"+data.label+"/status/" + this.ordertoken);}
            });
          }
          console.log(this.ordertoken)
        })
      }
    },
}
</script>

<style scoped lang="scss">
h1{
  margin-top: 1em;
}
.bn_tomypage{
  padding: 3em 0;
}
.statusform{
    border:solid 4px rgb(201, 56, 56);
    padding:2em;
    margin:1em auto;
    max-width: 650px;
    border-radius: .8em;
    .info{
      font-size: .8em;
      padding: 1em;
    }
    padding-top:1em ;
  dl{
  padding: 3em 0;
    border-bottom: solid 1px #ccc;
    dt{
      font-weight: bold;
      color: rgb(90, 90, 90);
      margin-bottom: 1em;
      }
    &:last-child{
      border-bottom: 0;
    }
  }
}
.statusinfo{
  font-size: .9em;
  margin-top: 2em;
}
</style>